import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout/layout.js'
import Content from '../../components/layout/content.js'
import SEO from '../../components/parts/seo.js'

const Thanks = () => {
  const pageTitle = {
    mainTitle: {
      text: 'お問い合わせありがとうございました'
    },
    subTitle: {
      text: '<Contact />',
      lang: 'en'
    }
  }

  return (
    <Layout pageTitle={pageTitle}>
      <SEO
        title='お問い合わせありがとうございました'
        description=''
        pathname='/contact/thanks/'
        article
      />
      <Content.Lead>
        <p>
          お問い合わせありがとうございました。<br />
          いただきましたお問い合わせメールは内容を確認したのち、必要に応じて対応しております。<br />
          返信にはお時間を頂戴する場合がございますので、あらかじめご了承ください。<br />
          また、入力内容の確認メールはございません。
        </p>
        <div className="button button_center">
          <Link to="/" className="button__inner button__inner_main">トップページへ戻る</Link>
        </div>
      </Content.Lead>
    </Layout>
  )
}
export default Thanks;
